import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as yup from 'yup';
import { buildValidationSchema, Confetti } from '@comicrelief/component-library';
import { snakeCase } from 'lodash';
import fatalErrorMessage from './fatalErrorMessage';

import {
  FIELDS,
  FIELDS_DEFAULT_VALUES,
  PRINTED_FUNDRAISING_PACK,
  DEFAULT_FR_GROUPS
} from './constants';
import withRecaptcha from '../../utils/withRecaptcha';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import DataProtectionNotice from './components/DataProtectionNotice';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { Wrapper, FormContainer } from './FundraiserSignup.style';
import {
  getSchoolId,
  getAddressId,
  getRules,
  handleCAPI,
  isMobileOrLandline,
  removeSpace
} from './utils';

import transformMarketingPref from '../../components/MarketingPrefs/_utils';
import SingleMessage from '../../components/SingleMessage/SingleMessage';
import FundraiserSignupStep1 from './FundraiserSignupStep1';
import FundraiserSignupStep2 from './FundraiserSignupStep2';
import FundraiserSignupStep3 from './FundraiserSignupStep3';
import FundraiserSignupStep4 from './FundraiserSignupStep4';
import FundraiserSignupContext from './context/FundraiserSignupContext';
import {
  BannerSingleMessagePropTypes,
  ContentfulImagePropTypes,
  ContentTextAreaPropTypes
} from './PropTypes';

// Adding a short delay between steps so that the user feels like something
// is happening and that they've successfully clicked the continue button!
const SLEEP_DURATION = 150;

const trackStep = (step, thisPreselectedGroup, thisCampaignCode) => {
  const event = 'cr-fundraising-reg';
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event,
    action: `Step${step}`,
    label: 'complete',
    fsu_url: window.location.pathname,
    fsu_preselected_group: thisPreselectedGroup,
    campaign_code: thisCampaignCode
  });
};

const trackSubmission = (group, uuid, segment, thisPreselectedGroup, thisCampaignCode) => {
  const event = `cr-${group}-reg`;
  const action = `cr-${group}-registration`;

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event,
    action,
    label: 'complete',
    fundraiser_signup_uuid: uuid,
    fsu_url: window.location.pathname,
    fsu_preselected_group: thisPreselectedGroup,
    campaign_code: thisCampaignCode
  });

  // Additional object as requested by Hiral
  window.dataLayer.push({
    event,
    action,
    label: 'complete',
    fundraiser_signup_uuid: uuid,
    fsu_subgroups: `cr-${group}-${segment}-reg`,
    fsu_preselected_group: thisPreselectedGroup,
    fsu_url: window.location.pathname,
    campaign_code: thisCampaignCode
  });
};

const FundraiserSignupPage = ({
  data: {
    contentfulPageFundraiserSignup2022: {
      path,
      campaignCode,
      bannerSingleMessage,
      summary,
      title,
      imageSeo,
      step1Title,
      step1Introduction,
      showPhoneNumberField,
      showTShirtField,
      step2Title,
      step2Introduction,
      defaultAudienceGroup,
      schoolsImage,
      workImage,
      publicImage,
      step3Title,
      step3Introduction,
      showFundraisingPackQuestion,
      fundraisingPackSegments,
      hideWhyDoWeCollectYourInfoSection,
      successMessage,
      justGivingPageSetup,
      justGivingLabel,
      justGivingDescription,
      goalsTitle,
      goalsDescription,
      goal1,
      goal1Image,
      goal2,
      goal2Image,
      goal3,
      goal3Image,
      goalOtherImage,
      successParagraphs = [],
      signupType
    }
  }
}) => {
  // Standard FSU: Hide the email input (use the values we've already collected), and
  // disable the postal entry as per https://comicrelief.atlassian.net/browse/ENG-3468
  const validationOverrides = {
    [FIELDS.MP_PERM_EMAIL]: { hideInput: true },
    [FIELDS.MP_PERM_POST]: { disableOption: true }
  };

  const { mpValidationOptions, mpValidationFields } = buildValidationSchema(validationOverrides);

  // Define main form's schema:
  const mainSchema = yup
    .object()
    .shape(getRules(showTShirtField, showPhoneNumberField));

  // ...and combine it with the MP schema provided by the component itself above
  const combinedSchema = mainSchema.shape(mpValidationFields);

  const methods = useForm({
    resolver: yupResolver(combinedSchema),
    mode: 'onBlur',
    shouldFocusError: true,
    shouldUnregister: true,
    defaultValues: FIELDS_DEFAULT_VALUES
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { isSubmitting, isValidating, errors }
  } = methods;

  const [step, setStep] = useState(1);
  const [showAddressInputs, setShowAddressInputs] = useState(false);
  const [isSleeping, setIsSleeping] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // see comments in step 2 continue button handler below about this showTopError stopgap measure
  const [showTopError, setShowTopError] = useState(false);
  const [hasFatalError, setHasFatalError] = useState(false);
  const [userUUID, setUserUUID] = useState(null);
  const [campaignUrl, setCampaignUrl] = useState(null);

  const [hasDefaultGroup, setHasDefaultGroup] = useState(false);
  const [preselectedGroup, setPreselectedGroup] = useState('none');

  const scrollAnchorRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const step1Options = {
    showPhoneNumberField,
    showTShirtField
  };

  const step2Options = {
    schoolsImage: schoolsImage?.file.url,
    workImage: workImage?.file.url,
    publicImage: publicImage?.file.url
  };

  const step3Options = {
    goalImages: {
      goal1Image: goal1Image?.file.url,
      goal2Image: goal2Image?.file.url,
      goal3Image: goal3Image?.file.url,
      goalOtherImage: goalOtherImage?.file.url
    },
    goals: {
      first: `£${goal1}`,
      second: `£${goal2}`,
      third: `£${goal3}`
    }
  };

  const fundraisingGroup = watch(FIELDS.GROUP);

  const doScroll = () => {
    if (scrollAnchorRef && scrollAnchorRef.current) {
      scrollAnchorRef.current.scrollIntoView();
    }
  };

  const getError = field => errors[field]?.message || '';

  const step1Handler = async (e) => {
    // Don't submit the form yet.
    e.preventDefault();
    // Validate the visible step 1 fields before advancing to step 2.
    const valid = await trigger([
      FIELDS.FIRST_NAME,
      FIELDS.LAST_NAME,
      FIELDS.EMAIL,
      FIELDS.CONFIRM_EMAIL
    ]);
    if (valid) {
      setIsSleeping(true);
      setTimeout(() => {
        setStep(2);
        trackStep(2, preselectedGroup, campaignCode);
        doScroll();
        setIsSleeping(false);
      }, SLEEP_DURATION);
    }
  };
  const step2Handler = async (e) => {
    // Don't submit the form yet.
    e.preventDefault();
    // Validating the visible step 2 fields before advancing to step 3.
    const valid = await trigger([
      FIELDS.GROUP,
      FIELDS.SEGMENT,
      FIELDS.ORG_NAME,
      FIELDS.INDUSTRY_TYPE,
      FIELDS.ORG_SIZE,
      FIELDS.JOB,
      FIELDS.LINE1,
      FIELDS.LINE2,
      FIELDS.LINE3,
      FIELDS.TOWN,
      FIELDS.POSTCODE
    ]);
    if (valid) {
      // see comments in 'else' branch below about this.
      setShowTopError(false);
      setIsSleeping(true);
      setTimeout(() => {
        setStep(3);
        trackStep(3, preselectedGroup, campaignCode);
        doScroll();
        setIsSleeping(false);

        // While we're here, let's pass the user's already-supplied details to the MP form:
        setValue(FIELDS.MP_EMAIL, getValues(FIELDS.EMAIL), {
          shouldValidate: true
        });
        setValue(FIELDS.MP_LINE1, getValues(FIELDS.LINE1), {
          shouldValidate: true
        });
        // Changes to react-hook-form means that getValues now returns 'undefined'
        // rather than empty string if a field is empty. As such, we're now having to handle this
        // to ensure the MP address fields are actually set to SOMETHING
        // in order to keep the B/E happy, given that LINE2 and LINE3 are optional,
        // so potentially empty.
        setValue(FIELDS.MP_LINE2, getValues(FIELDS.LINE2) || '', {
          shouldValidate: true
        });
        setValue(FIELDS.MP_LINE3, getValues(FIELDS.LINE3) || '', {
          shouldValidate: true
        });
        setValue(FIELDS.MP_TOWN, getValues(FIELDS.TOWN), {
          shouldValidate: true
        });
        setValue(FIELDS.MP_POSTCODE, getValues(FIELDS.POSTCODE), {
          shouldValidate: true
        });
        // No 'country' value in the main form, but need to supply one for MPs:
        setValue(FIELDS.MP_COUNTRY, 'GB', { shouldValidate: true });
        // Use our helper function to determine which MP field to populate if any.
        // NB: Because we're stripping spaces at a state level (via Yup transform),
        // not from the actual field itself, we need to do the same here:
        let suppliedPhone = getValues(FIELDS.PHONE) || '';
        if (suppliedPhone !== undefined && suppliedPhone !== '') {
          suppliedPhone = removeSpace(suppliedPhone);
          // Determine which MP options are suitable for this type of number
          const theseMPFields = isMobileOrLandline(suppliedPhone);
          theseMPFields.forEach((field) => {
            setValue(field, suppliedPhone, { shouldValidate: true });
          });
        }
      }, SLEEP_DURATION);
    } else {
      // TODO: focus the first error input
      // note: react-hook-form's imminent version 7.7 will add the
      // ability to focus error when `trigger()` fails
      // see: https://github.com/react-hook-form/react-hook-form/pull/4960
      //
      // For now we can just set the top error message and scroll to top
      // of form if there are errors.
      // Note: we only need this on step 2. Step 3 _will_ focus the top
      // validation error (validation on form submit - rather than via
      // trigger() - does this); step 1 is too small for it to matter.
      setShowTopError(true);
      doScroll();
    }
  };

  useEffect(() => {
    // I don't know why this fixes things
    watch();

    // On initial mount/render, format any default group setting:
    const formattedGroup = snakeCase(defaultAudienceGroup).toUpperCase();

    // Check it's legit:
    if (Object.keys(DEFAULT_FR_GROUPS).includes(formattedGroup)) {
      // If so, map the CMS value (which is the just dropdown label value),
      // to the value we actually care about:
      setValue(FIELDS.GROUP, DEFAULT_FR_GROUPS[formattedGroup]);
      // Update our flag to let us hide all group buttons:
      setHasDefaultGroup(true);
      setPreselectedGroup(DEFAULT_FR_GROUPS[formattedGroup]);
      // Having to set these trackStep params directly here as state
      // (preselectedGroup) gets updated too late to use:
      trackStep(1, DEFAULT_FR_GROUPS[formattedGroup], campaignCode);
    } else {
      // Otherwise, use our default "none" preselectedGroup
      // state value since this isn't a preselected group FSU
      trackStep(1, preselectedGroup, campaignCode);
    }
  }, []);

  const submitHandler = async (formData) => {
    try {
      const schoolId = selectedSchool && getSchoolId({ formData, selectedSchool });
      const addressId = selectedAddress && getAddressId({ formData, selectedAddress });
      // const prizeDrawOptIn = Boolean(formData.prize_draw_opt_in);
      const thisFormData = formData;
      let thisUUID;
      // Default to 'no JG' values
      let justGivingStory = null;
      let justGivingPageTitle = null;
      let tools = [];
      // Provivded by the backend to allow us to signpost the
      // user onwards, should there be any submission error:
      let thisCampaignUrl;

      // Only use actual values where appropriate
      if (justGivingPageSetup) {
        tools = ['justgiving_page_setup'];
        justGivingStory = thisFormData.jg_page_story;
        justGivingPageTitle = thisFormData.jg_page_title;
      }

      // Transform the MP permission values now we're using MP's built-in validation
      // NB: A future piece of work will transform within the MP component itself,
      // given the '1/0/null' data requirement seems to be global.
      Object.keys(formData).forEach((field) => {
        if (field.includes('mp_permission')) {
          thisFormData[field] = transformMarketingPref(formData[field]);
        }
      });

      // Massage the fundraising pack back into a usable format by the backend
      if (
        thisFormData[FIELDS.FUNDRAISING_PACK]
        === PRINTED_FUNDRAISING_PACK.YES.value
      ) {
        tools.push('fundraising_pack');
      }
      // remove this field as its now handled by the tools array
      delete thisFormData[FIELDS.FUNDRAISING_PACK];
      // remove the over_eighteen value as this isn't stored.
      delete thisFormData.over_eighteen;

      // As this is optional in the backend, ensure we're not passing a false positive here
      if (!thisFormData[FIELDS.ORG_SIZE]) thisFormData[FIELDS.ORG_SIZE] = null;
      if (!thisFormData[FIELDS.INDUSTRY_TYPE]) thisFormData[FIELDS.INDUSTRY_TYPE] = null;
      if (!thisFormData[FIELDS.JOB]) thisFormData[FIELDS.JOB] = null;

      const data = {
        ...thisFormData,
        // Short-term fix as yup's "default" no longer seems to allow
        // us to set an empty string where we need it for the B/E:
        address_line_2: thisFormData.address_line_2 || '',
        address_line_3: thisFormData.address_line_3 || '',
        address_id: addressId,
        org_id: schoolId,
        org_id_type: schoolId && 'crosl',
        campaign_code: campaignCode,
        fundraising_tools: tools,
        // This is no longer being used as part of the form
        // however it's expected by downstream services so we tack it on here.
        fundraising_ideas: [],
        mp_transSourceUrl: window.location.href,
        // prize_draw_opt_in: prizeDrawOptIn
        jg_page_story: justGivingStory,
        jg_page_title: justGivingPageTitle,
        // Manually adding to this to keep B/E happy,
        // now that we've removed it.
        mp_permissionPost: null
      };

      const reCaptchaToken = await executeRecaptcha('fsu');
      await axios
        .post(`${process.env.GATSBY_FSU_SUBMIT_URL}/signup`, data, {
          params: { recaptcha_token: reCaptchaToken },
          headers: { 'Content-Type': 'application/json' },
          timeout: 30000
        })
        .then((response) => {
          thisUUID = response.data.data.id;
          thisCampaignUrl = response.data.data.jgCampaignUrl;
        });

      trackSubmission(formData[FIELDS.GROUP], thisUUID,
        formData[FIELDS.SEGMENT], preselectedGroup, campaignCode);
      setUserUUID(thisUUID);
      setCampaignUrl(thisCampaignUrl);

      setStep(4);
      doScroll();

      // Once we've successfully submitted, pass the userFields to trigger the CAPI event
      handleCAPI(formData, thisUUID);
    } catch (error) {
      if (typeof Sentry !== 'undefined') {
        Sentry.captureException(error);
      }
      doScroll();
      setHasFatalError(true);
    }
  };

  const hideInfo = Boolean(hideWhyDoWeCollectYourInfoSection);

  // Adding autoComplete="off" to all the text inputs to hopefully improve data quality.
  // (Although I think Chrome ignores this annoyingly...)
  return (
    <Layout>
      <SEO
        title={`${title} | Comic Relief`}
        description={summary?.summary || ''}
        image={imageSeo?.file?.url || ''}
        pathname={path}
      />
      <Wrapper data-campaign={campaignCode} className="fsu-wrapper">
        <SingleMessage {...bannerSingleMessage} />
        <FormContainer css={{ position: 'relative' }}>

          <div
            css={{ position: 'absolute', top: '-75px' }}
            ref={scrollAnchorRef}
          />
          <ErrorBoundary
            showError={hasFatalError}
            errorMessage={fatalErrorMessage}
          >
            <form
              onSubmit={handleSubmit(submitHandler)}
              noValidate
              autoComplete="off"
            >
              <FundraiserSignupContext.Provider
                value={{
                  step,
                  register,
                  isValidating,
                  isSleeping,
                  isSubmitting,
                  getError,
                  watch,
                  fundraisingGroup,
                  methods,
                  setStep,
                  setValue,
                  getValues
                }}
              >
                {/* Personal Details */}
                <FundraiserSignupStep1
                  title={step1Title}
                  description={step1Introduction}
                  handler={step1Handler}
                  options={step1Options}
                />
                {/* Fundraising Group Details */}
                <FundraiserSignupStep2
                  title={step2Title}
                  description={step2Introduction}
                  handler={step2Handler}
                  showTopError={showTopError}
                  showAddressInputs={showAddressInputs}
                  setSelectedAddress={setSelectedAddress}
                  setSelectedSchool={setSelectedSchool}
                  setShowAddressInputs={setShowAddressInputs}
                  options={step2Options}
                  hasDefaultGroup={hasDefaultGroup}
                  campaignCode={campaignCode}
                />
                {/* Fundraising Tools/Goals */}
                <FundraiserSignupStep3
                  title={step3Title}
                  description={step3Introduction}
                  signupType={signupType}
                  justGivingLabel={justGivingLabel}
                  justGivingDescription={justGivingDescription}
                  goalsTitle={goalsTitle}
                  goalsDescription={goalsDescription}
                  mpValidationOptions={mpValidationOptions}
                  options={step3Options}
                  showFundraisingPackQuestion={showFundraisingPackQuestion}
                  fundraisingPackSegments={fundraisingPackSegments}
                  showJGQuestion={justGivingPageSetup}
                  campaignCode={campaignCode}
                />
                {/* Success */}
                <FundraiserSignupStep4
                  successMessage={successMessage}
                  successParagraphs={successParagraphs}
                  userUUID={userUUID}
                  campaignCode={campaignCode}
                  // Only load the loader when the CMS option has the JG integration enabled
                  showJGPageLinkLoader={justGivingPageSetup}
                  campaignUrl={campaignUrl}
                />
              </FundraiserSignupContext.Provider>
            </form>
          </ErrorBoundary>
          <Confetti trigger={step === 4} />

          {/* Simpler privacy notice copy */}
          {step < 4 && hideInfo && (
            <DataProtectionNotice
              showReducedCopy={hideInfo}
            />
          )}

        </FormContainer>

        {/* The FULL privacy notice copy */}
        {step < 4 && !hideInfo && (
        <DataProtectionNotice
          hasPhoneField
          showReducedCopy={hideInfo}
        />
        )}
      </Wrapper>
    </Layout>
  );
};

FundraiserSignupPage.propTypes = {
  data: PropTypes.shape({
    contentfulPageFundraiserSignup2022: PropTypes.shape({
      path: PropTypes.string.isRequired,
      campaignCode: PropTypes.string.isRequired,
      bannerSingleMessage: BannerSingleMessagePropTypes,
      summary: PropTypes.shape({
        summary: PropTypes.string.isRequired
      }).isRequired,
      title: PropTypes.string.isRequired,
      imageSeo: ContentfulImagePropTypes.isRequired,
      step1Title: PropTypes.string.isRequired,
      step1Introduction: ContentTextAreaPropTypes.isRequired,
      showPhoneNumberField: PropTypes.bool.isRequired,
      showTShirtField: PropTypes.bool.isRequired,
      step2Title: PropTypes.string.isRequired,
      step2Introduction: ContentTextAreaPropTypes.isRequired,
      defaultAudienceGroup: PropTypes.string,
      schoolsImage: ContentfulImagePropTypes,
      workImage: ContentfulImagePropTypes,
      publicImage: ContentfulImagePropTypes,
      step3Title: PropTypes.string.isRequired,
      step3Introduction: ContentTextAreaPropTypes.isRequired,
      showFundraisingPackQuestion: PropTypes.bool.isRequired,
      fundraisingPackSegments: PropTypes.arrayOf(PropTypes.string),
      hideWhyDoWeCollectYourInfoSection: PropTypes.bool.isRequired,
      justGivingPageSetup: PropTypes.bool.isRequired,
      justGivingLabel: PropTypes.string.isRequired,
      justGivingDescription: ContentTextAreaPropTypes.isRequired,
      goalsTitle: PropTypes.string.isRequired,
      goalsDescription: ContentTextAreaPropTypes.isRequired,
      goal1: PropTypes.string,
      goal1Image: ContentfulImagePropTypes,
      goal2: PropTypes.string,
      goal2Image: ContentfulImagePropTypes,
      goal3: PropTypes.string,
      goal3Image: ContentfulImagePropTypes,
      goalOtherImage: ContentfulImagePropTypes,
      successMessage: ContentTextAreaPropTypes.isRequired,
      // eslint-disable-next-line
      successParagraphs: PropTypes.arrayOf(PropTypes.object),
      signupType: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export const pageQuery = graphql`
  query FundraiserSignupPage($id: String) {
    contentfulPageFundraiserSignup2022(id: { eq: $id }) {
      path
      campaignCode
      title
      signupType
      summary {
        summary
      }
      imageSeo {
        file {
          url
        }
      }
      bannerSingleMessage {
        ...SingleMessageParagraph
      }
      step1Title

      step1Introduction {
        raw
      }
      showTShirtField
      showPhoneNumberField
      step2Title
      step2Introduction {
        raw
      }
      defaultAudienceGroup
      schoolsImage {
        file {
          url
        }
      }
      workImage {
        file {
          url
        }
      }
      publicImage {
        file {
          url
        }
      }
      step3Title
      step3Introduction {
        raw
      }
      showFundraisingPackQuestion
      fundraisingPackSegments
      hideWhyDoWeCollectYourInfoSection
      justGivingPageSetup
      justGivingLabel
      justGivingDescription {
        raw
      }
      goalsTitle
      goalsDescription {
        raw
      }
      goal1
      goal1Image {
        file {
          url
        }
      }
      goal2
      goal2Image {
        file {
          url
        }
      }
      goal3
      goal3Image {
        file {
          url
        }
      }
      goalOtherImage {
        file {
          url
        }
      }
      successMessage {
        raw
      }
      successParagraphs {
        id
        ...SingleMessageDsParagraph
      }
    }
  }
`;

export default withRecaptcha(FundraiserSignupPage);
